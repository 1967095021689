import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import accountsItem from "@/modules/profile/section/accounts-item/index.vue";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import uk from 'vue2-datepicker/locale/uk'
import ru from 'vue2-datepicker/locale/ru'

//sections
import moment from "moment";

export default {
    name: "accounts",
    components: {
        mainSelect,
        DatePicker
    },
    comments: {
        accountsItem,

    },
    data() {
        return {
            tablesIndexes: ['0'],
            selectTime: '',
            selectType: '',
            dates: null,
            lang: uk
        }
    },

    created() {
        this.$route.params.lang === 'uk' ? this.lang = uk : this.lang = ru
        this.getDocuments({})
    },
    watch: {
        dates() {
            let obj = {}
            if (this.dates) {
                let dayFrom = this.dates[0].getDate(),
                    monthFrom = this.dates[0].getMonth() + 1,
                    yearFrom = this.dates[0].getFullYear(),
                    dayTo = this.dates[1].getDate(),
                    monthTo = this.dates[1].getMonth() + 1,
                    yearTo = this.dates[1].getFullYear();

                obj.date_from = `${yearFrom}-${monthFrom}-${dayFrom}`
                obj.date_to = `${yearTo}-${monthTo}-${dayTo}`

            }

            this.getDocuments(obj)
        }
    },
    computed: {
        ...mapGetters({
            userDocuments: 'profile/userDocuments',
            documentLoadingStatus: 'profile/documentLoadingStatus'
        }),
        getPlaceholderDatePicker() {
            return `${moment().set({ date: 1 }).format('DD-MM-YYYY')} ~ ${moment().format('DD-MM-YYYY')}`
        }
    },
    filters: {
        /**
         * @param {number} value
         * @return {string}
         */
        formatDocPrice(value) {
            if (!value) {
                return '-'
            }
            const formatted = new Intl.NumberFormat('uk').format(value);

            if (Math.abs(Number.parseInt(formatted)) === 0) {
                return '-'
            }

            return formatted;
        }
    },
    methods: {
        ...mapActions({
            getDocuments: 'profile/GET_DOCUMENTS'
        }),
        isOpenedTable(...index) {
            return this.tablesIndexes.findIndex(el => el === index.join(',')) !== -1
        },
        openTable(...index) {
            let table = this.tablesIndexes.findIndex(el => el === index.join(','))
            table !== -1 ? this.tablesIndexes.splice(table, 1) : this.tablesIndexes.push(index.join(','))
        },
        openPopup(data) {
            this.isShowProfileDocument({status: true, data: data})
        },
        ...mapMutations({
            isShowProfileDocument: 'profile/SET_DOCUMENT_POPUP'
        }),
        getLocalTotal(data){
            let incoming = 0;
            let outgoing = 0;
            let remainder = 0;
            let respData = {
                incoming:0,
                outgoing:0,
                remainder:0
            };
            data.documents.data.map(function(item){
                if(item.id > 0){
                    if(item.name.includes("Накладна на повернення") || item.name.includes("Возврат товаров")){
                        incoming -= item.incoming;
                        outgoing += item.outgoing;
                    }else if(item.name.includes("Повернення коштів") || item.name.includes("Расходный кассовый ордер")){
                        incoming += item.incoming;
                        outgoing -= item.outgoing;
                    }else{
                        incoming += item.incoming;
                        outgoing += item.outgoing;
                    }
                }
                else{
                    remainder = item.result;
                }
            });

            respData.incoming = incoming.toFixed(2);
            respData.outgoing = outgoing.toFixed(2);
            respData.remainder = ((incoming + remainder) - outgoing).toFixed(2);

            return respData;
        },
        getFaceRemainder(userDocument){
            let thisContext = this;
            let faceRemainder = {
                incoming:0,
                outgoing:0,
                remainder:0
            };
            if(userDocument.dogovors.data.length > 0){
                userDocument.dogovors.data.map(function(dogovor){
                    let dogovorRemainder = thisContext.getLocalTotal(dogovor);

                    if(!isNaN(parseFloat(dogovorRemainder.incoming))){
                        faceRemainder.incoming += parseFloat(dogovorRemainder.incoming);
                    }
                    if(!isNaN(parseFloat(dogovorRemainder.outgoing))){
                        faceRemainder.outgoing += parseFloat(dogovorRemainder.outgoing);
                    }
                    if(!isNaN(parseFloat(dogovorRemainder.remainder))){
                        faceRemainder.remainder += parseFloat(dogovorRemainder.remainder);
                    }
                });
            }

            faceRemainder.incoming = faceRemainder.incoming.toFixed(2);
            faceRemainder.outgoing = faceRemainder.outgoing.toFixed(2);
            faceRemainder.remainder = faceRemainder.remainder.toFixed(2);

            return faceRemainder;
        },
        getTotalRemainder(){
            let totalRemainder = {
                incoming:0,
                outgoing:0,
                remainder:0
            };
            let thisContext = this;
            this.userDocuments.documents.map(function(userDocument){
                let faceRemainder = thisContext.getFaceRemainder(userDocument);

                if(!isNaN(parseFloat(faceRemainder.incoming))){
                    totalRemainder.incoming += parseFloat(faceRemainder.incoming);
                }
                if(!isNaN(parseFloat(faceRemainder.outgoing))){
                    totalRemainder.outgoing += parseFloat(faceRemainder.outgoing);
                }
                if(!isNaN(parseFloat(faceRemainder.remainder))){
                    totalRemainder.remainder += parseFloat(faceRemainder.remainder);
                }
            });

            totalRemainder.incoming = totalRemainder.incoming.toFixed(2);
            totalRemainder.outgoing = totalRemainder.outgoing.toFixed(2);
            totalRemainder.remainder = totalRemainder.remainder.toFixed(2);

            return totalRemainder;
        },

        digitsFormatting(number){
            if(isNaN(number)){return 0.00;}
            number = Math.round(number * 100) / 100;
            number = new Intl.NumberFormat("ru").format(number);
            return number;
        }

        // disabledData(date) {
        //     let today = new Date();
        //     today.setDate(today.getDate() - 1);
        //     return date < today;
        // },
    }
}
